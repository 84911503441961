import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import FormLoginVnsodo from 'components/FormLoginVnsodo';
import FormCounter from 'components/FormCouter';
import { dynamicImg } from 'assets/images';
import GameHot from 'components/GameHot';
import Footer from 'components/Footer';
import FormDownload from 'components/FormDownload';
import { Box, Grid, Theme } from '@material-ui/core';
import { PHONE } from 'utils/secrets';
import AsyncImg from 'components/AsyncImg';

type PhoneNumberProps = {
  className?: string;
};

function PhoneNumber({ className }: PhoneNumberProps) {
  return (
    <div className={className}>
      <AsyncImg src={dynamicImg.phone} alt="logo" />
      <a href={`tel:${PHONE.replaceAll(' ', '')}`}>{PHONE}</a>
    </div>
  );
}
const listImage: Promise<any>[] = [dynamicImg.p1, dynamicImg.p2, dynamicImg.p3];
function HomePageVnSodo() {
  const classes = useStyles();
  return (
    <div className={`${classes.root}`}>
      <Grid
        container
        direction="row"
        justify="space-between"
        alignItems="stretch"
        className={classes.container}>
        <Grid item lg={3} md={3} sm={2} xs={12}>
          <Box pt={5} className={`${classes.sessionLeft}`}>
            <GameHot width={266} />
          </Box>
        </Grid>
        <Grid item lg={6} md={6} sm={8} xs={12}>
          <Box pt={5} pb={3}>
            <AsyncImg
              className={classes.logo}
              src={dynamicImg.logo}
              alt="logo"
              effect="blur"
            />
            <div>
              <AsyncImg
                className={classes.bellowLogo}
                src={dynamicImg.bellowLogo}
                alt="bellowLogo"
                effect="blur"
              />
            </div>
          </Box>
          <Grid className={classes.containerForm}>
            <Box className={`${classes.sessionCenter}`}>
              {/* <TextRunAlway /> */}
              <FormLoginVnsodo />
              {/* <Box pt={5} className={`${classes.phoneContactMobile}`}>
                <PhoneNumber className={classes.phoneNumberIcon} />
              </Box> */}
              <FormDownload
                width={266}
                vnsodo
                className={`${classes.downloadMobile}`}
              />
            </Box>
          </Grid>
        </Grid>
        <Grid item lg={3} md={3} sm={2} xs={12}>
          <Box pt={5} className={`${classes.sessionRight}`}>
            {/* <PhoneNumber className={classes.phoneNumberIcon} /> */}
          </Box>
          <Box pt={2} pb={3} className={`${classes.sessionRight} `}>
            <FormCounter listImage={listImage} width={266} />
          </Box>
          <Box className={`${classes.sessionRight} `}>
            <FormDownload width={266} vnsodo />
          </Box>
        </Grid>
        <Grid item xs={12} lg={12}>
          <Box mt={10}>
            <Footer />
          </Box>
        </Grid>
      </Grid>
      <div className={`${classes.menuMobile}`}>{/* <MenuMobile /> */}</div>
    </div>
  );
}

export default HomePageVnSodo;

const useStyles = makeStyles<Theme>((theme) => ({
  root: {},
  logo: {
    objectFit: 'cover',
    width: '100%',
  },
  bellowLogo: {
    marginTop: '50px',
    width: '800px',
    objectFit: 'cover',
    [theme.breakpoints.up('xl')]: {
      marginTop: '50px',
      width: '900px',
    },
    [theme.breakpoints.down('lg')]: {
      marginTop: '20px',
      width: '650px',
    },
    [theme.breakpoints.down('md')]: {
      marginTop: '20px',
      width: '400px',
    },
    [theme.breakpoints.down('sm')]: {
      width: '300px',
      marginTop: '0px',
    },
  },
  container: {
    backgroundRepeat: 'no-repeat',
    backgroundSize: '100% auto,cover',
    backgroundPositionY: 100,
    backgroundAttachment: 'fixed',
    width: '100%',
    marginRight: 'auto',
    marginLeft: 'auto',
    // '@media (min-width: 1400px)': {
    //   maxWidth: '1320px',
    // },
  },

  sessionRight: {
    paddingRight: '20px',
    display: 'flex',
    justifyContent: 'flex-end',
    '@media (max-width: 1280px)': {
      display: 'none',
    },
  },
  phoneNumberIcon: {
    width: '266px',
    cursor: 'pointer',
    display: 'flex',
    alignItems: 'center',
    textAlign: 'center',
    '& img': {
      width: '100%',
      objectFit: 'contain',
    },
    '& a': {
      marginLeft: '-205px',
      color: 'white !important',
      paddingLeft: '50px',
      fontWeight: 'bold',
      fontSize: '20px',
      zIndex: '10',
      textDecoration: 'none',
    },
  },
  sessionLeft: {
    paddingLeft: '20px',
    display: 'flex',
    justifyContent: 'start',
    '@media (max-width: 1280px)': {
      display: 'none',
    },
  },
  containerForm: {
    display: 'flex',
    justifyContent: 'center',
  },
  sessionCenter: {
    maxWidth: '640px',
    [theme.breakpoints.up('lg')]: {
      paddingLeft: '65px',
      paddingRight: '65px',
    },
    [theme.breakpoints.down('sm')]: {
      padding: '0 10px 0 10px',
    },
  },

  menuMobile: {
    [theme.breakpoints.up('lg')]: {
      display: 'none',
    },
  },
  phoneContactMobile: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    [theme.breakpoints.up('lg')]: {
      display: 'none',
    },
  },

  downloadMobile: {
    marginRight: 'auto',
    marginLeft: 'auto',
    '@media (min-width: 1280px)': {
      display: 'none',
    },
  },
}));
