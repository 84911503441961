import React from 'react';
import {
  createMuiTheme,
  makeStyles,
  Theme,
  ThemeProvider,
} from '@material-ui/core/styles';
import TextInputControl from 'components/TextInputControl';
import { TextField } from '@material-ui/core';
import { LazyLoadImage } from 'react-lazy-load-image-component';

export interface Props {
  label?: string;
  type?: string;
  height?: any;
  width?: any;
  className?: string;
  icon: string;
  value?: string;
  onChange?: React.ComponentProps<typeof TextInputControl>['onChange'];
  error?: boolean;
}

function CustomInput(props: Props) {
  const classes = useStyles();
  return (
    <div
      className={`search-bar-wrapper ${props.error && classes.error} ${
        classes.root
      }`}
      style={{ width: props.width, height: props.height }}>
      <div className="d-flex align-items-center">
        <div className="search-icon-wrapper">
          <LazyLoadImage src={props.icon} alt="Icon" effect="blur" />
        </div>
        <div className={`text-input ${classes.textInput}`}>
          <ThemeProvider theme={theme}>
            <TextField
              style={{ height: 50 }}
              type={props.type as any}
              label={props.label}
              variant="filled"
              value={props.value}
              fullWidth
              InputProps={{ classes: { input: classes.input } }}
              onChange={props.onChange}
              error={props.error}
            />
          </ThemeProvider>
        </div>
      </div>
    </div>
  );
}

export default CustomInput;

const useStyles = makeStyles<Theme>((theme) => ({
  root: {
    borderRadius: theme.spacing(3),
    border: 'solid 1.5px gray',
    padding: '0 20px',
    backgroundColor: 'white',
  },
  error: {
    border: 'solid 1.5px red !important',
  },
  textInput: {
    width: '100%',
  },
  input: {
    zIndex: 100,
  },
}));

const theme = createMuiTheme({
  overrides: {
    MuiOutlinedInput: {
      notchedOutline: {
        backgroundColor: 'white',
        border: 'none',
        top: '0px',
        bottom: '0px',
      },
    },
    MuiFilledInput: {
      underline: {
        background: 'none !important',
        '&::before': {
          border: 'none !important',
        },
        '&::after': {
          border: 'none !important',
        },
      },
    },
  },
});
