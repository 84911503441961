import React from 'react';
import HomePage68Sodo from './container/68sodo';
import './App.scss';
import { IntlProvider } from 'react-intl';
import { ProvideLang, useLang } from './utils/hooks/use-lang';
import { PLATFORM } from './utils/secrets';
import HomePageVnSodo from './container/vnsodo';
import { bg68SodoMin, bgVnSodoMin } from './assets/images';

type IntlProviderProps = React.ComponentProps<typeof IntlProvider>;

function loadLocaleData(locale: string) {
  switch (locale) {
    default:
      return import('./assets/languages/vi.json');
  }
}

type Props = {
  locale: IntlProviderProps['locale'];
  messages: {
    vi: IntlProviderProps['messages'];
    en: IntlProviderProps['messages'];
  };
};

function App(props: Props) {
  const lang = useLang();
  const renderHomePage = () => {
    switch (PLATFORM.toLowerCase()) {
      case '68sodo':
        window.document.body.style.backgroundImage = `url(${bg68SodoMin})`;
        return <HomePage68Sodo />;
        break;
      case 'vnsodo':
      case 'vn58':
        window.document.body.style.backgroundImage = `url(${bgVnSodoMin})`;
        return <HomePageVnSodo />;
        break;
      default:
        window.document.body.style.backgroundImage = `url(${bg68SodoMin})`;
        return <HomePage68Sodo />;
    }
  };
  return (
    <React.StrictMode>
      <ProvideLang>
        <IntlProvider messages={props.messages[lang.local]} locale={lang.local}>
          <div className="App">{renderHomePage()}</div>
        </IntlProvider>
      </ProvideLang>
    </React.StrictMode>
  );
}

export async function bootstrapApplication(locale = 'vi') {
  const messages = {
    vi: await (await loadLocaleData('vi')).default,
    en: await (await loadLocaleData('en')).default,
  };

  return <App locale={locale} messages={messages} />;
}
