import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Box, Grid } from '@material-ui/core';
import { PLATFORM_DOMAIN } from 'utils/secrets';
function Footer() {
  const classes = useStyles();
  return (
    <div className="footer">
      <Grid item xs={12} md={12}>
        <Box pb={2} className={`${classes.title}`}>
          <div>{`@${PLATFORM_DOMAIN} Inc. All right reserved`}</div>
        </Box>
      </Grid>
    </div>
  );
}

export default Footer;

const useStyles = makeStyles({
  title: {
    color: 'white',
    fontSize: '22px',
  },
});
