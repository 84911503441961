import React, { useState, useContext, createContext } from 'react';

const langContext = createContext(
  {} as {
    local: 'vi' | 'en';
    setLocal(lang: 'vi' | 'en'): any;
  },
);

// Provider component that wraps our app and makes lang object available to any
// child component that calls useLang().
export function ProvideLang({ children }: any) {
  const lang = useProvideLang();
  return <langContext.Provider value={lang}>{children}</langContext.Provider>;
}

// Hook for child components to get the lang object and re-render when it
// changes.
export const useLang = () => {
  return useContext(langContext);
};

// Provider hook that creates lang object and handles state
function useProvideLang() {
  const [local, setLocal] = useState('vi') as any;

  // Return the user object and lang methods
  return {
    local,
    setLocal,
  };
}
