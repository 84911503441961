import { makeStyles, Theme } from '@material-ui/core/styles';
import React from 'react';
import { googlePlay, Appstore, dynamicImg } from 'assets/images';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import AsyncImg from 'components/AsyncImg';
import { DOWNLOAD_URL } from 'utils/secrets';

export interface Props {
  width?: number;
  widthIcon?: number;
  vnsodo?: boolean;
  className?: string;
}
function FormDownload(props: Props) {
  const classes = useStyles(props);
  return (
    <div className={`form-login ${classes.root} ${props.className}`}>
      <div className={`${classes.row}`}>
        <div className={`col-sm-12 pt-2 pb-3 ${classes.title}`}>
          <AsyncImg
            src={dynamicImg.lienKetUngDung}
            alt="lienKetUngDung"
            effect="blur"
          />
        </div>
        <div
          className={`col-sm-12 pb-3 ${props.vnsodo && 'pt-3'} ${
            classes.logoDownload
          }`}>
          <a href={DOWNLOAD_URL}>
            <LazyLoadImage src={Appstore} alt="Appstore" effect="blur" />
          </a>
        </div>
        <div className={`col-sm-12 pb-3 ${classes.logoDownload}`}>
          <a href={DOWNLOAD_URL}>
            <LazyLoadImage src={googlePlay} alt="googlePlay" effect="blur" />
          </a>
        </div>
      </div>
    </div>
  );
}

export default FormDownload;

const useStyles = makeStyles<Theme, Props>(() => ({
  root: {
    backgroundColor: '#050606',
    width: ({ width }) => (width ? `${width}px` : '100%'),
    borderRadius: '10px',
    border: '2px solid #6e3e2a',
  },
  row: {
    // margin: '10px 10px 0px 10px',
  },
  title: {
    color: 'white',
    textAlign: 'center',
    fontWeight: 'bold',
    backgroundImage: ({ vnsodo }) =>
      vnsodo ? 'linear-gradient(#f67554, #d84613)' : 'none',
    width: '100%',
    borderTopLeftRadius: '8px',
    borderTopRightRadius: '8px',
    padding: '15px',
  },
  buttonTop: {
    width: '100%',
  },
  logoDownload: {
    cursor: 'pointer',
    '& img ': {
      width: ({ widthIcon }) => (widthIcon ? `${widthIcon}px` : '100%'),
    },
  },
}));
