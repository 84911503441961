import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import FormLogin from 'components/FormLogin';
import FormCounter from 'components/FormCouter';
import { canhPhai, canhTrai, dynamicImg } from 'assets/images';
import GameHot from 'components/GameHot';
import Footer from 'components/Footer';
import FormDownload from 'components/FormDownload';
// import MenuMobile from 'components/MenuMobile';
import { Box, Grid, Theme } from '@material-ui/core';
import { PHONE } from 'utils/secrets';
import AsyncImg from 'components/AsyncImg';
import { LazyLoadImage } from 'react-lazy-load-image-component';

type PhoneNumberProps = {
  className?: string;
};

function PhoneNumber({ className }: PhoneNumberProps) {
  return (
    <div className={className}>
      <AsyncImg src={dynamicImg.phone} alt="logo" />
      <a href={`tel:${PHONE.replaceAll(' ', '')}`}>{PHONE}</a>
    </div>
  );
}
const listImage: Promise<any>[] = [
  dynamicImg.p1,
  dynamicImg.p2,
  dynamicImg.p3,
  dynamicImg.p4,
  dynamicImg.p5,
  dynamicImg.p6,
  dynamicImg.p7,
];
function HomePage68Sodo() {
  const classes = useStyles();
  return (
    <div className={`${classes.root}`}>
      <Grid
        container
        direction="row"
        justify="space-between"
        alignItems="stretch"
        className={classes.container}>
        <Grid item xs={12} md={12} lg={12} sm={12}>
          <Box pt={1} pb={3}>
            <div className={classes.groupLogo}>
              <LazyLoadImage
                className={classes.canhTrai}
                src={canhTrai}
                alt=""
                effect="blur"
              />
              <LazyLoadImage
                className={classes.canhPhai}
                src={canhPhai}
                alt=""
                effect="blur"
              />
            </div>
          </Box>
        </Grid>
        <Grid item xs={12} md={3} lg={3} sm={2}>
          <Box pt={25} className={`${classes.sessionLeft}`}>
            <GameHot logo />
          </Box>
        </Grid>
        <Grid item lg={6} md={6} xs={12} sm={8}>
          <Box pt={1} pb={3}>
            <AsyncImg
              className={classes.logo}
              src={dynamicImg.logo}
              alt="logo"
              effect="blur"
            />
            <div>
              <AsyncImg
                className={classes.bellowLogo}
                src={dynamicImg.bellowLogo}
                alt="bellowLogo"
                effect="blur"
              />
            </div>
          </Box>
          <Box className={`${classes.sessionCenter}`}>
            {/* <TextRunAlway /> */}
            <FormLogin />
            <div className={`d-fex pb-5 ${classes.phoneContactMobile}`}>
              <PhoneNumber className={classes.phoneNumberIcon} />
            </div>
          </Box>
        </Grid>
        <Grid item lg={3} xs={12} md={3} sm={2}>
          <Box
            pt={1}
            pb={10}
            className={`${classes.sessionRight} ${classes.phoneContactDesktop}`}>
            <PhoneNumber className={classes.phoneNumberIcon} />
          </Box>
          {/* <Box
            pb={1}
            className={`${classes.sessionRight} ${classes.sessionRightLoaIcon}`}>
            <img src={loa} alt="logo" />
          </Box> */}
          <Box pt={5} pb={3} className={`${classes.sessionRight} `}>
            <FormCounter listImage={listImage} />
          </Box>
          <Box className={`${classes.sessionRight} `}>
            <FormDownload widthIcon={200} />
          </Box>
        </Grid>
        <Grid item xs={12} lg={12}>
          <Footer />
        </Grid>
      </Grid>
      <div className={`${classes.menuMobile}`}>{/* <MenuMobile /> */}</div>
    </div>
  );
}

export default HomePage68Sodo;

const useStyles = makeStyles<Theme>((theme) => ({
  root: {},
  groupLogo: {
    marginBottom: '-300px',
  },
  canhTrai: {
    objectFit: 'cover',
    paddingRight: '140px',
    [theme.breakpoints.up('lg')]: {
      paddingRight: '140px',
    },
    [theme.breakpoints.down('md')]: {
      paddingRight: '0px',
    },
    [theme.breakpoints.down('sm')]: {
      width: '125px',
    },
  },
  logo: {
    objectFit: 'cover',
    width: '100%',
    marginTop: '-30px',
    [theme.breakpoints.down('md')]: {
      marginTop: '30px',
    },
    [theme.breakpoints.down('sm')]: {
      marginTop: '-40px',
    },
    [theme.breakpoints.between('xs', 'sm')]: {
      marginTop: '0px',
      width: '70%',
    },
  },
  canhPhai: {
    objectFit: 'cover',
    paddingLeft: '140px',
    [theme.breakpoints.up('lg')]: {
      paddingLeft: '140px',
    },
    [theme.breakpoints.down('md')]: {
      paddingLeft: '0px',
    },
    [theme.breakpoints.down('sm')]: {
      width: '125px',
    },
  },
  bellowLogo: {
    marginTop: '-110px',
    width: '380px',
    objectFit: 'cover',
    [theme.breakpoints.down('lg')]: {
      marginTop: '-70px',
      paddingBottom: '0px',
    },
    [theme.breakpoints.down('md')]: {
      width: '200px',
    },
    [theme.breakpoints.down('sm')]: {
      width: '180px',
      marginTop: '-80px',
      paddingBottom: '0px',
    },
  },
  container: {
    backgroundRepeat: 'no-repeat',
    backgroundSize: '100% auto,cover',
    backgroundPositionY: 100,
    backgroundAttachment: 'fixed',
    width: '100%',
    marginRight: 'auto',
    marginLeft: 'auto',
    '@media (min-width: 1400px)': {
      maxWidth: '1320px',
    },
  },

  sessionRight: {
    '@media (max-width: 1280px)': {
      display: 'none',
    },
  },
  sessionRightLoaIcon: {
    '& img': {
      objectFit: 'cover',
      width: '100px',
    },
  },
  phoneNumberIcon: {
    cursor: 'pointer',
    paddingTop: '50px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    textAlign: 'center',
    '& img': {
      width: '220px',
      objectFit: 'contain',
      paddingRight: 5,
    },
    '& a': {
      marginLeft: '-205px',
      color: 'black !important',
      paddingLeft: '50px',
      fontWeight: 'bold',
      fontSize: '20px',
      zIndex: '10',
      textDecoration: 'none',
    },
  },
  phoneContactDesktop: {
    '@media (max-width: 1280px)': {
      display: 'none',
    },
  },
  sessionLeft: {
    '@media (max-width: 1280px)': {
      display: 'none',
    },
  },
  sessionCenter: {
    [theme.breakpoints.up('lg')]: {
      paddingLeft: '65px',
      paddingRight: '65px',
    },
    [theme.breakpoints.down('sm')]: {
      padding: '0 10px 0 10px',
    },
  },

  menuMobile: {
    [theme.breakpoints.up('lg')]: {
      display: 'none',
    },
  },
  phoneContactMobile: {
    [theme.breakpoints.up('lg')]: {
      display: 'none',
    },
  },
}));
