import React, { useState } from 'react';
import { Box, CircularProgress } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { dynamicImg, txtButtonSignUp } from 'assets/images';
import { accountSignUp } from 'services/account/account';
import { SHARE_LINK } from 'utils/secrets';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import TextInputControl from 'components/TextInputControl';
import AsyncImg from 'components/AsyncImg';
import { accountLogin } from 'services/account';
import { appendTokenToSsoLink } from 'utils/sign-up';

function FormLoginVnsodo() {
  const classes = useStyles();
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirm] = useState('');
  const [phone, setPhone] = useState('');
  const [isLoading, setIsLoading] = useState(false);

  const onSubmit = () => {
    const _username = username.replace(/\s/g, '');
    const _phone = phone.replace(/\s/g, '').replace(/^0/, '+84');
    if (
      _username.length > 5 &&
      password === confirmPassword &&
      password.length > 5 &&
      // code &&
      _phone
    ) {
      setIsLoading(true);
      accountSignUp({
        username: _username,
        password,
        shareLink: parseInt(SHARE_LINK),
        phone: _phone,
      })
        .then(() => {
          accountLogin(_username, password)
            .then((token) => {
              if (
                confirm(
                  'Bạn đã đăng ký thành công và sẽ được chuyển tiếp tới ứng dụng',
                )
              ) {
                window.location.href = appendTokenToSsoLink(token);
              }
            })
            .catch(() => alert('Vui lòng thử lại'));
        })
        .catch(() => alert('Vui lòng thử lại'))
        .finally(() => setIsLoading(false));
    } else {
      alert('Vui lòng thử lại');
    }
  };

  return (
    <div className={`${classes.rootWrapper}`}>
      <div className={`form-login ${classes.root}`}>
        <div className={`row ${classes.rowWrapper}`}>
          <Box pb={4} pt={2} className={classes.groupButton}>
            <button className={classes.buttonTopLeft} disabled>
              <AsyncImg
                src={dynamicImg.dangKyNormal}
                alt="dangKyNormal"
                effect="blur"
              />
            </button>
          </Box>
          <Box pb={3}>
            <TextInputControl
              label="Tên đăng nhập"
              value={username}
              onChange={(e) => setUsername(e.target.value)}
              error={username.length < 6 && username.length > 0}
            />
          </Box>
          <Box pb={3}>
            <TextInputControl
              label="Nhập mật khẩu"
              type="password"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              error={password.length < 6 && password.length > 0}
            />
          </Box>
          <Box pb={3}>
            <TextInputControl
              label="Nhập lại mật khẩu"
              type="password"
              value={confirmPassword}
              onChange={(e) => setConfirm(e.target.value)}
              error={confirmPassword.length > 0 && confirmPassword !== password}
            />
          </Box>
          <Box pb={3}>
            <TextInputControl
              label="Điện thoại"
              type="text"
              value={phone}
              onChange={(e) => setPhone(e.target.value)}
              placeholder="+84xxx xxx xxx"
            />
          </Box>
          {/* <Box pb={3}>
            <TextInputControl
              label="Mã đại lý"
              type="number"
              value={code}
              onChange={(e) => setCode(e.target.value)}
            />
          </Box> */}
          <Box pb={3} className={classes.groupButtonBottom}>
            <button
              className={classes.buttonBottomLeft}
              onClick={() => onSubmit()}
              disabled={isLoading}>
              <LazyLoadImage
                src={txtButtonSignUp}
                alt="buttonDangKyNgay"
                effect="blur"
              />
              {isLoading && (
                <CircularProgress className={classes.buttonLoading} />
              )}
              {/* <span className={classes.gifBButtonSignUp}>
                <LazyLoadImage
                  src={gifBButtonSignUp}
                  alt="buttonDangKyNgay"
                  effect="blur"
                />
              </span> */}
            </button>
          </Box>
        </div>
      </div>
    </div>
  );
}

export default FormLoginVnsodo;

const useStyles = makeStyles(() => ({
  rootWrapper: {
    width: '100%',
  },
  root: {
    backgroundColor: 'transparent',
    width: '100%',
    color: 'white',
  },
  rowWrapper: {
    margin: '0px 0px 0px 0px',
  },
  groupButton: {
    display: 'flex',
    justifyContent: 'center',
  },
  buttonTopLeft: {
    backgroundColor: 'transparent',
    border: 'none',
    '& img': {
      width: '100%',
    },
  },
  logo: {
    width: '100px',
    objectFit: 'cover',
  },
  groupButtonBottom: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'flex-end',
    // marginTop: '80px',
    '@media (max-width: 600px)': {
      marginTop: '40px',
    },
  },
  buttonBottomLeft: {
    backgroundImage: 'linear-gradient(#f67554, #d84613)',
    borderRadius: '8px',
    height: '70px',
    width: '311px',
    border: 'none',
    position: 'relative',
  },
  buttonLoading: {
    position: 'absolute',
  },
  gifBButtonSignUp: {
    position: 'absolute',
    '& img': {
      position: 'relative',
      top: '-100px',
      right: '15px',
      '@media (max-width: 600px)': {
        width: '110px',
        top: '-60px',
        right: '0px',
      },
    },
  },
}));
