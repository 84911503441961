import React from 'react';
import { makeStyles, Theme } from '@material-ui/core/styles';
import { Carousel } from 'react-responsive-carousel';
import AsyncImg from 'components/AsyncImg';
export interface Props {
  listImage: Promise<any>[];
  titleSlide?: Promise<any>;
  width?: number;
}
const FormCounter = (props: Props) => {
  const classes = useStyles(props);
  return (
    <div className={`form-counter ${classes.formCounter}`}>
      <div className="">
        {props.titleSlide && (
          <div className={`col-sm-12 header ${classes.header}`}>
            <AsyncImg src={props.titleSlide} alt="promoTitle" effect="blur" />
          </div>
        )}
        <div className={`col-sm-12 header ${classes.carousel}`}>
          <Carousel
            showThumbs={false}
            swipeable
            infiniteLoop
            autoPlay
            emulateTouch>
            {props.listImage.map((img, i) => (
              <div key={i}>
                <AsyncImg src={img} alt="promoTitle" effect="blur" />
              </div>
            ))}
          </Carousel>
        </div>
      </div>
    </div>
  );
};

export default FormCounter;

const useStyles = makeStyles<Theme, Props>(() => ({
  formCounter: {
    width: ({ width }) => (width ? `${width}px` : '100%'),
    borderRadius: '8px',
  },
  header: {
    backgroundImage: 'linear-gradient(#602e3e76, #050606)',
    border: 'solid 2px #744d3f',
    width: '100%',
    color: 'white',
    fontWeight: 'bold',
    fontSize: '20px',
    textAlign: 'center',
    alignItems: 'center',
    padding: '10px 0 10px 0',
    borderTopRightRadius: '8px',
    borderTopLeftRadius: '8px',

    '& img': {
      maxWidth: '90%',
    },
  },
  carousel: {
    marginTop: 2,
    width: '100%',
    '& img': {
      width: '100%',
    },
    borderBottomLeftRadius: 8,
    borderBottomRightRadius: 8,
    overflow: 'hidden',
  },
  number: {
    color: 'red',
    fontWeight: 'bold',
    fontSize: '30px',
    textAlign: 'center',
    alignItems: 'center',
    padding: '10px 0 10px 0',
    marginBottom: '10px',
  },
  input: {
    padding: '10px',
    marginBottom: '10px',
  },
}));
