import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Grid, Theme } from '@material-ui/core';
import { bauCua, dynamicImg } from 'assets/images';
import AsyncImg from 'components/AsyncImg';
import { LazyLoadImage } from 'react-lazy-load-image-component';
export interface Props {
  width?: number;
  logo?: boolean;
}
function GameHot(props: Props) {
  const classes = useStyles(props);
  return (
    <div className={`${classes.root}`}>
      <Grid
        container
        direction="column"
        justify="flex-start"
        alignItems="center">
        {props.logo && (
          <Grid item xs={12}>
            <LazyLoadImage
              className={classes.logoGame}
              src={bauCua}
              alt="logo"
              effect="blur"
            />
          </Grid>
        )}
        <Grid item xs={12}>
          <AsyncImg
            className={classes.gameHot1}
            src={dynamicImg.gameHot1}
            alt="gameHot1"
            effect="blur"
          />
        </Grid>
      </Grid>
    </div>
  );
}

export default GameHot;

const useStyles = makeStyles<Theme, Props>({
  root: {
    width: ({ width }) => (width ? `${width}` : '100%'),
  },
  gameHot1: {
    objectFit: 'cover',
    width: '100%',
  },
  logoGame: {
    position: 'relative',
    width: '150px',
    top: '50px',
  },
});
