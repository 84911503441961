import React, { useState } from 'react';
import { Box } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import {
  buttonDangKyNgay,
  dangNhap,
  dynamicImg,
  multipleUser,
  padlock003,
} from 'assets/images';
import CustomInput from 'components/CustomInput';
import { accountSignUp } from 'services/account/account';
import { PLATFORM_DOMAIN, SHARE_LINK } from 'utils/secrets';
import AsyncImg from 'components/AsyncImg';
import { LazyLoadImage } from 'react-lazy-load-image-component';

function FormLogin() {
  const classes = useStyles();
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirm] = useState('');
  const onSubmit = () => {
    if (
      username.length > 6 &&
      password === confirmPassword &&
      password.length > 0
    ) {
      accountSignUp({
        username,
        password,
        shareLink: parseInt(SHARE_LINK),
      })
        .then(() => {
          if (
            confirm(
              'Bạn đã đăng ký thành công và sẽ được chuyển tiếp tới ứng dụng',
            )
          ) {
            window.location.href = `https://${PLATFORM_DOMAIN}`;
          }
        })
        .catch(() => alert('Vui lòng thử lại'));
    } else {
      alert('Vui lòng thử lại');
    }
  };
  return (
    <div className={`${classes.rootWrapper}`}>
      <div className={`form-login ${classes.root}`}>
        <div className={`row ${classes.rowWrapper}`}>
          <Box pb={2} pt={2} className={classes.groupButton}>
            <button className={classes.buttonTopLeft}>
              <AsyncImg
                src={dynamicImg.dangKyNormal}
                alt="dangKyNormal"
                effect="blur"
              />
            </button>
            <button
              onClick={() =>
                (window.location.href = `https://${PLATFORM_DOMAIN}`)
              }
              className={classes.buttonTopRight}>
              <LazyLoadImage src={dangNhap} alt="dangNhap" effect="blur" />
            </button>
          </Box>
          <Box pb={2}>
            <CustomInput
              label="Tên đăng nhập"
              icon={multipleUser}
              value={username}
              onChange={(e) => setUsername(e.target.value)}
              error={username.length < 6 && username.length > 0}
            />
          </Box>
          <Box pb={2}>
            <CustomInput
              label="Nhập mật khẩu"
              type="password"
              icon={padlock003}
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              error={password.length < 6 && password.length > 0}
            />
          </Box>
          <div className="col-sm-12 pb-4">
            <CustomInput
              label="Nhập lại mật khẩu"
              type="password"
              icon={padlock003}
              value={confirmPassword}
              onChange={(e) => setConfirm(e.target.value)}
              error={confirmPassword.length > 0 && confirmPassword !== password}
            />
          </div>
          {/* <Box pb={2}>
            <CustomInput label="Mã đại lý" icon={linkSvg} />
          </Box> */}
          <Box pb={2} className={classes.buttonBottomLeft}>
            <button type="submit" onClick={() => onSubmit()}>
              <LazyLoadImage
                src={buttonDangKyNgay}
                alt="buttonDangKyNgay"
                effect="blur"
                style={{ width: '100%' }}
              />
            </button>
          </Box>
        </div>
      </div>
    </div>
  );
}

export default FormLogin;

const useStyles = makeStyles(() => ({
  rootWrapper: {
    width: '100%',
    borderRadius: '32px',
    border: '4px solid #8d0509',
  },
  root: {
    backgroundColor: '#250121',
    width: '100%',
    borderRadius: '28px',
    border: '10px solid #f8dd8f',
    color: 'white',
  },
  rowWrapper: {
    margin: '0px 0px 0px 0px',
  },
  groupButton: {
    display: 'flex',
    justifyContent: 'center',
  },
  buttonTopLeft: {
    backgroundColor: 'transparent',
    border: 'none',
    '& img': {
      width: '100%',
    },
  },
  buttonTopRight: {
    backgroundColor: 'transparent',
    border: 'none',
    '& img': {
      width: '100%',
    },
  },
  logo: {
    width: '100px',
    objectFit: 'cover',
  },
  buttonBottomLeft: {
    marginBottom: '-53px',
    '& button': {
      backgroundColor: 'transparent',
      width: '60%',
      border: 'none',
    },
    '@media (max-width: 576px)': {
      marginBottom: '-43px',
    },
  },
}));
