import { ApiError } from 'services/ApiError';
import { MS_API } from 'utils/api';

type SignUpData = {
  username: string;
  password: string;
  shareLink: number;
  phone?: string;
};

export const accountSignUp = (data: SignUpData) => {
  return new Promise<void>((resolve, reject) => {
    MS_API.post<{ _id: string }>('/account/signup', {
      ...data,
    })
      .then(() => {
        resolve();
      })
      .catch((e: ApiError) => {
        reject('error.' + (e.response?.data.code || 'default_error'));
      });
  });
};
