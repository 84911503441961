import React from 'react';
import {
  createMuiTheme,
  createStyles,
  makeStyles,
  TextField,
  Theme,
  ThemeProvider,
} from '@material-ui/core';

export interface Props {
  label?: string;
  type?: string;
  width?: number;
  height?: number;
  value?: string;
  onChange?: React.ComponentProps<typeof TextField>['onChange'];
  error?: boolean;
  placeholder?: string;
}

function TextInputControl(props: Props) {
  const classes = useStyles(props);
  return (
    <div className={`text-input ${classes.root}`}>
      <ThemeProvider theme={theme}>
        <TextField
          style={{ height: 50 }}
          type={props.type as any}
          label={props.label}
          variant="filled"
          value={props.value}
          fullWidth
          InputProps={{ classes: { input: classes.input } }}
          onChange={props.onChange}
          error={props.error}
          placeholder={props.placeholder}
        />
      </ThemeProvider>
    </div>
  );
}

export default TextInputControl;

const useStyles = makeStyles<Theme, Props>((theme) =>
  createStyles({
    root: {
      width: ({ width }) => width ?? '100%',
    },
    input: {
      zIndex: 100,
    },
  }),
);

const theme = createMuiTheme({
  overrides: {
    MuiOutlinedInput: {
      root: {
        // fontSize: '20px',
      },
      input: {
        // padding: '16px 14px',
        // height: 56,
        // boxSizing: 'border-box',
      },
      notchedOutline: {
        backgroundColor: '#a68990',
        border: 'none',
        top: '0px',
        bottom: '0px',
      },
    },
    MuiFilledInput: {
      root: {
        color: '#a68990',
      },
      underline: {
        background: 'transparent !important',
        border: '2px solid #8d3931',
        borderRadius: '8px',
        '&::before': {
          border: 'none !important',
        },
        '&::after': {
          border: 'none !important',
        },
      },
    },
    MuiInputLabel: {
      root: {
        '&.Mui-focused': {
          color: '#a68990',
        },
      },
      filled: {
        color: '#a68990',
      },
      // outlined: {
      //   transform: 'translate(14px, 19px) scale(1)',
      // },
    },
  },
});
