import { MS_API } from 'utils/api';
import { ApiError } from 'services/ApiError';

export const accountLogin = (
  username: string,
  password: string,
  captcha = 'mock',
) => {
  return new Promise<string>((resolve, reject) => {
    const data = new FormData();
    data.append('username', username);
    data.append('password', password);
    data.append('captcha', captcha);

    MS_API.post<{ access_token: string }>('/account/login', data, {
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded',
      },
    })
      .then((res) => {
        const token = res.data.access_token;
        if (token) {
          resolve(token);
        } else {
          reject('error.login_error');
        }
      })
      .catch((e: ApiError) =>
        reject('error.' + (e.response?.data.code || 'default_error')),
      );
  });
};
