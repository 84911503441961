import React, { useEffect, useState } from 'react';
import { LazyLoadImage } from 'react-lazy-load-image-component';

export interface Props {
  src: Promise<any>;
  slot?: string;
  extClass?: string;
  onClick?: any;
  className?: string;
  alt?: string;
  effect?: string;
  style?: {
    width?: string;
    height?: string;
  };
}

function AsyncImg(props: Props) {
  const [imgSrc, setImg] = useState<any>();
  const { src, className, onClick, alt, effect, style } = props;
  useEffect(() => {
    src &&
      src.then((s: any) => {
        setImg(s.default);
      });
  }, []);
  return (
    <LazyLoadImage
      className={className || ''}
      src={imgSrc}
      onClick={() => onClick && onClick()}
      alt={alt || ''}
      effect={effect ?? ('blur' as any)}
      style={style}
    />
  );
}

export default AsyncImg;
